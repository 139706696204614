<template>
  <div>
    <div class="flix-form-group">
      <span class="date">
        <flixIcon class="icon" :id="'calendar'" />
        <select v-model="startSplit[0][2]" class="flix-form-control">
          <option v-for="days in Array(31).keys()" :key="days" :value="$getNullsBefore(1 + days)">{{ 1 + days }}</option>
        </select>
        <select v-model="startSplit[0][1]" class="flix-form-control">
          <option v-for="months in Array(12).keys()" :key="months" :value="$getNullsBefore(1 + months)">{{ $t('constants.months')[1 + months] }}</option>
        </select>
        <select v-model="startSplit[0][0]" class="flix-form-control">
          <option v-for="year in Array(2).keys()" :key="year" :value="new Date().getFullYear() + year">{{ new Date().getFullYear() + year }}</option>
        </select>
      </span>
      <span class="hours">
        <flixIcon class="icon" :id="'clock'" />
        <select v-model="startSplit[1][0]" class="flix-form-control">
          <option v-for="hours in Array(24).keys()" :key="hours" :value="$getNullsBefore(hours)">{{ $getNullsBefore(hours ) }}</option>
        </select>
        <select v-model="startSplit[1][1]" class="flix-form-control">
          <option v-for="minutes in Array(4).keys()" :key="minutes" :value="$getNullsBefore(minutes * 15)">{{ $getNullsBefore(minutes * 15) }}</option>
          <option v-if="startSplit[1][0] == 23" :value="$getNullsBefore(59)">{{ $getNullsBefore(59) }}</option>
        </select>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { date: String, callback: Function },
  data () {
    return {
      startSplit: this.getStart(),
      start: this.date
    }
  },
  methods: {
    getStart () {
      if (!this.date) {
        return ''
      }

      var r = this.date
      r = r.split(' ')
      r[0] = r[0].split('-')
      r[1] = r[1].split(':')
      return r
    }
  },
  watch: {
    startSplit (v) { if (v[1][1].toString() === '59' && v[1][0].toString() !== '23') { v[1][1] = '00' } this.start = v[0][0] + '-' + v[0][1] + '-' + v[0][2] + ' ' + v[1][0] + ':' + v[1][1]; this.callback(this.start) }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .icon
    font-size: 24pt
    margin-right: 8px
    &:nth-of-type(2)
      margin-left: 8px

  .flix-form-control
    display: inline-block
    width: auto

  .hours
    display: inline-block
    margin-left: 10px

  @media (max-width: 500px)
    .icon
      font-size: 20pt
      margin-bottom: 10px
      display: block

    .flix-form-control
      display: block
      margin-bottom: 5px
      width: 100%
      text-align: center
    .hours
      display: block
      margin-top: 10px
      margin-left: 0
</style>
